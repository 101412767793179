<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              class="text-uppercase"
              width="20%"
            >
              {{ $t('permiso.permiso') }}
            </th>
            <th
              v-for="(perfil, i) in perfiles"
              :key="`e${i}`"
              class="text-uppercase"
              width="30%"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="3"
                  class="text-center"
                >
                  <span>{{ $t('permiso.ver') }}</span>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="text-center"
                >
                  <span>{{ $t('permiso.edit') }}</span>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="text-center"
                >
                  <span>{{ $t('permiso.create') }}</span>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  class="text-center"
                >
                  <span>{{ $t('permiso.delete') }}</span>
                </v-col>
              </v-row>
            </th>
          </tr>
          <tr>
            <th
              class="text-uppercase"
            >
              {{ $t('permiso.zonas') }} / {{ $t('permiso.profils') }}
            </th>
            <th
              v-for="(perfil, i) in perfiles"
              :key="i"
              class="text-uppercase"
            >
              {{ perfil.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <v-row>
                <v-col
                  cols="12"
                  md="10"
                >
                  {{ $t('menu.booking') }}
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="permisos_booking"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mdiMagnify, mdiDeleteOutline, mdiInformation } from '@mdi/js'

export default {
  data() {
    return {
      permisos_booking: false,
      search: '',
      items: [],
      perfiles: [],
      pagination: {
        current: 1,
        total: 0,
      },
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiInformation,
      },
      isDialogVisible: false,
      editItem: {},
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  created() {
    this.getPerfiles()
    this.load()
  },
  methods: {
    load() {
      this.axios
        .get(`permiso?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${this.search}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page
          }
        })
    },
    getPerfiles() {
      this.axios
        .get('perfil_afiliado?per_page=1000', {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) { } else {
            this.perfiles = res.data.data
          }
        })
    },
    onPageChange() {
      this.load()
    },
    editDescription(item) {
      this.editItem = item
      this.isDialogVisible = true
    },
    updateItem() {
      if (this.editItem.description && this.editItem.name) {
        this.axios
          .put(
            `permiso/${this.editItem.id}`,
            { description: this.editItem.description, name: this.editItem.name },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            if (res.data.success) {
              this.editItem = {}
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isDialogVisible = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
